import Appbar from '../components/Appbar';

export default function Home(){
    const menuOptions = [
        { label: "Login", to: process.env.REACT_APP_SERVER + 'Login' }
      ];
    return(
        <>
        <Appbar menuOptions={menuOptions}/>
        <h1>Home</h1>
        </>
    );
}