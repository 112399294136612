import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Typography, Drawer, Container, TextField } from '@mui/material';
import { helpHttp } from "../helpers/helpHttp";

const UsersData = [
  { Codigo: 3, Nombre: 'Prueba Alvaro Rodriguez', Telefono: 3005776735, Email: 'aa_9310@hotmail.com', 'Nombre Referidor': 'Alvaro Rodriguez' }
];

const tableHeader = {
  backgroundColor: process.env.REACT_APP_APPBAR_COLOR,
  color: 'white',
  padding: '12px',
  textAlign: 'left',
};

const RefferedByUser = () => {
  const [users, setUsers] = useState(UsersData);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [referrals, setReferrals] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const sessionData = JSON.parse(sessionStorage.getItem('sessionData')) || {};

  const fetchReferrals = async (userId) => {
    try {
      const apiResponse = await helpHttp().get(`${process.env.REACT_APP_USERS_REFFERED_SERVICE}?idEmpresa=${sessionData.idEmpresa}&usuarioReferidor=${userId}`);
      if (!apiResponse.err) {
        const formattedReferrals = apiResponse.map((referral) => ({
          level: parseInt(referral.Referidos.match(/Nivel (\d+):/)[1], 10),
          referred: referral.Referidos.replace(/Nivel \d+: /, '')
        }));
        setReferrals(formattedReferrals);
      }
    } catch (error) {
      console.error("Error al obtener referidos:", error);
    }
  };

  const handleDrawerOpen = async (userId) => {
    setSelectedUserId(userId);
    setDrawerOpen(true);
    await fetchReferrals(userId);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
    setSelectedUserId(null);
    setReferrals([]);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredUsers = users.filter((user) =>
    user.Nombre.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const tableCell = {
    padding: '8px',
    textAlign: 'left',
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiResponse = await helpHttp().get(`${process.env.REACT_APP_COMPANIES_REFFERED_SERVICE}?idEmpresa=${sessionData.idEmpresa}`);
        if (!apiResponse.err) {
          setUsers(apiResponse);
        }
      } catch (error) {
        console.error("Error al obtener usuarios:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <Container>
      <div style={{ display: 'flex' }}>
        <div style={{ flexGrow: 1, padding: '20px' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
            <Typography variant="h4">Panel de Referidos</Typography>
            <div>
              <TextField
                label="Buscar por nombre"
                variant="outlined"
                size="small"
                value={searchTerm}
                onChange={handleSearch}
              />
            </div>
          </div>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  {Object.keys(users[0]).map((columnName) => (
                    <TableCell key={columnName} style={tableHeader}>
                      {columnName}
                    </TableCell>
                  ))}
                  <TableCell style={tableHeader}>Acciones</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredUsers.map((user) => (
                  <TableRow key={user.Codigo}>
                    {Object.keys(user).map((columnName, index) => (
                      <TableCell key={user.Codigo + "-" + columnName} style={tableCell}>
                        {user[columnName]}
                      </TableCell>
                    ))}
                    <TableCell>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => handleDrawerOpen(user.Codigo)}
                      >
                        Ver Referidos
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerClose}>
          <div style={{ width: '80%', maxWidth: 400, padding: 20, margin: 'auto' }}>
            <Typography variant="h5">Referidos de Usuario</Typography>
            {referrals.map((referral, index) => (
              <Typography key={index} variant="body1">
                Nivel {referral.level}: {referral.referred}
              </Typography>
            ))}
          </div>
        </Drawer>
      </div>
    </Container>
  );
};

export default RefferedByUser;
