import React, { useState, useEffect } from 'react';
import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Typography, Drawer, Container, TextField, Select, MenuItem } from '@mui/material';
import SignUp from './SignUp';
import { useParams } from "react-router-dom";
import { helpHttp } from "../helpers/helpHttp";

const AdminDashboard = () => {
  const params = useParams();
  const [Users, setUsers] = useState([]);
  const [Roles, setDbRoles] = useState([]);
  const [editingUserId, setEditingUserId] = useState(null);
  const [editedUserData, setEditedUserData] = useState({});
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorApi, setErrorApi] = useState(null);
  const [okForm, setOkForm] = useState(null);


  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
    setEditingUserId(null);
    setEditedUserData({});
    setIsEditing(false);
  };

  const handleSaveUser = async (userId, updatedUserData) => {
    try {
      setLoading(true);
      await new Promise((resolve) => setTimeout(resolve, 1000));

      const updatedUsers = Users.map((user) =>
        user['Codigo Usuario'] === userId ? { ...user, ...updatedUserData } : user
      );
      setUsers(updatedUsers);

      const requestData = {
        body: updatedUserData,
        headers: { "content-type": "application/json" },
      };

      helpHttp()
        .put(process.env.REACT_APP_SIGN_UP_SERVICE + process.env.REACT_APP_UPDATE_USERS_SERVICE, requestData)
        .then((res) => {
          if (res.codigo === 0) {
            setOkForm(res);
          } else {
            setErrorApi(`Error al guardar el usuario: ${res.message}`);
          }
          setLoading(false);
        });
    } catch (error) {
      console.error('Error al guardar el usuario: ', error.message);
    } finally {
      setEditingUserId(null);
      setEditedUserData({});
      setIsEditing(false);
    }
  };

  useEffect(() => {
    const defaultResponse = [
      {
        'Codigo Usuario': -1,
      }
    ];    

    const sessionData = JSON.parse(sessionStorage.getItem('sessionData')) || {};
    
    const fetchData = async () => {
      try {

        // Obtiene los roles de usuarios
        const rolesRes = await helpHttp().get(process.env.REACT_APP_ROLES_SERVICE);
        if (!rolesRes.err) {
          setDbRoles(rolesRes);
        } else {
          setDbRoles(null);
        }

        const apiResponse = await helpHttp().get(process.env.REACT_APP_SIGN_UP_SERVICE + process.env.REACT_APP_LIST_USERS_SERVICE + '?empresa=' + sessionData.idEmpresa+'&idUsuario='+sessionData.idUsuario);
        if (!apiResponse.err && Object.keys(apiResponse).length !== 0) {
          setUsers(apiResponse);
        } else {
          console.error("Servicio abajo db:", defaultResponse);
          setUsers(defaultResponse);
        }
      } catch (error) {
        console.error("Error en el efecto:", error);
      }
    };

    fetchData();

    console.log('apiresponse 2 : ', Users);
  }, []);

  const tableHeader = {
    backgroundColor: process.env.REACT_APP_APPBAR_COLOR,
    color: 'white',
    padding: '12px',
    textAlign: 'left',
  };

  const tableCell = {
    padding: '8px',
    textAlign: 'left',
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredUsers = Users.length > 0 && Users[0]["Codigo Usuario"] !== -1 ?
    Users.filter((user) =>
      user.Nombre && typeof user.Nombre === 'string' && user.Nombre.toLowerCase().includes(searchTerm.toLowerCase())
    ) : [];

  return (
    <Container>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
        <Typography variant="h4" style={{ marginTop: '20px' }}>Panel de Administración de usuarios</Typography>
        <div>
          <TextField
            label="Buscar por nombre"
            variant="outlined"
            size="small"
            value={searchTerm}
            onChange={handleSearch}
          />
          <Button
            variant="contained"
            color="success"
            style={{ backgroundColor: process.env.REACT_APP_APPBAR_COLOR, marginLeft: '10px' }}
            onClick={handleDrawerOpen}
          >
            Agregar Usuario
          </Button>
        </div>
      </div>
      {Users && Users.length > 0 && Users[0]['Codigo Usuario'] !== -1 ? (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {Object.keys(Users[0]).map((columnName) => (
                  <TableCell key={columnName} style={tableHeader}>
                    {columnName}
                  </TableCell>
                ))}
                <TableCell style={tableHeader}>Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredUsers.map((user) => (
                <TableRow key={user['Codigo Usuario']}>
                  {Object.keys(user).map((columnName) => (
                    <TableCell key={`${user['Codigo Usuario']}-${columnName}`} style={tableCell}>
                      {editingUserId === user['Codigo Usuario'] ? (
                        columnName === 'Rol' ? (
                          <Select
                            value={editedUserData[columnName] || ''}
                            onChange={(e) => {
                              const value = e.target.value;
                              setEditedUserData((prevFields) => ({
                                ...prevFields,
                                [columnName]: value,
                              }));
                            }}
                          >
                            {Roles.map((role) => (
                              <MenuItem key={role.codigo} value={role.descripcion}>
                                {role.descripcion}
                              </MenuItem>
                            ))}
                          </Select>
                        ) : columnName === 'Estado' ? (
                          <Select
                            value={editedUserData[columnName] || ''}
                            onChange={(e) => {
                              const value = e.target.value;
                              setEditedUserData((prevFields) => ({
                                ...prevFields,
                                [columnName]: value,
                              }));
                            }}
                          >
                            <MenuItem value="Activo">Activo</MenuItem>
                            <MenuItem value="Inactivo">Inactivo</MenuItem>
                          </Select>
                        ) : (
                          <TextField
                            type={columnName === 'Contraseña' ? 'password' : 'text'}
                            value={columnName === 'Contraseña' ? (editedUserData[columnName] || '') : editedUserData[columnName]}
                            onChange={(e) => {
                              const value = e.target.value;
                              setEditedUserData((prevFields) => ({
                                ...prevFields,
                                [columnName]: value,
                              }));
                            }}
                            placeholder={columnName === 'Contraseña' ? 'Nueva Contraseña' : ''}
                          />
                        )
                      ) : (
                        columnName === 'Contraseña' ? (
                          '*****'
                        ) : (
                          user[columnName]
                        )
                      )}
                    </TableCell>
                  ))}
                  <TableCell style={tableCell}>
                    {editingUserId === user['Codigo Usuario'] ? (
                      <>
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => handleSaveUser(user['Codigo Usuario'], editedUserData)}
                        >
                          Guardar
                        </Button>
                        <Button
                          variant="outlined"
                          color="error"
                          onClick={handleDrawerClose}
                        >
                          Cancelar
                        </Button>
                      </>
                    ) : (
                      <Button
                        variant="outlined"
                        color="primary"
                        disabled={editingUserId !== null}
                        onClick={() => {
                          setEditingUserId(user['Codigo Usuario']);
                          setEditedUserData({ ...user, 'Contraseña': '' }); // Clear the password field
                        }}
                      >
                        Editar
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography variant="body1">Cargando...</Typography>
      )}
      <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerClose}>
        <div style={{ width: '80%', maxWidth: 400, padding: 20, margin: 'auto' }}>
          <SignUp />
        </div>
      </Drawer>
    </Container>
  );
};

export default AdminDashboard;
