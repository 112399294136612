import React from 'react';

export const Message = ({ msg, bgColor, onAccept }) => {
  const styles = {
    padding: '1rem',
    marginBottom: '1rem',
    marginTop: '1rem',
    textAlign: 'center',
    color: '#fff',
    fontWeight: 'bold',
    backgroundColor: bgColor,
    borderRadius: '8px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    maxWidth: '400px',
    margin: '0 auto',
};

  const buttonStyles = {
    padding: '0.5rem 1rem',
    background: '#fff',
    color: bgColor,
    border: `2px solid ${bgColor}`,
    borderRadius: '4px',
    cursor: 'pointer',
    marginTop: '1rem',
};

  const handleRefresh = () => {
    if (typeof onAccept === 'function') {
      onAccept(); // Llama a la función de retorno proporcionada como prop
    }
    else{
      window.location.reload();
    }
  };

  return (
    <div style={styles}>
      <p>{msg}</p>
      <button style={buttonStyles} onClick={handleRefresh}>Aceptar</button>
    </div>
  );
};


