import { React, useState, useEffect } from "react";

export const helpHttp =()=>{
    

    const customFetch = (endpoint, options)=> { 
        
        //const [data, setData] = useState(null);

        console.log('Begin customFetch');
        const defaultHeader ={
            accept: "application/json",
        }
        //Error handler not responding
        const controller = new AbortController();
        options.signal = controller.signal;

        options.method  = options.method || "GET";
        options.headers = options.headers
        ?{...defaultHeader,...options.headers}
        :defaultHeader; 

        options.body = JSON.stringify(options.body)||false;
        if(!options.body)delete options.body;

        console.log(options);
        
        const  timeout = 3000;
        //setTimeout({}=>controller.abort(),3000);
        setTimeout(() => {
            controller.abort()    
        }, timeout);

        console.log('fetch: '+endpoint);
        
        return fetch(endpoint, options)
            //  .then((res)=>
            //      res.ok
            //          ? res.json()
            //           :Promise.reject({
            //               err:true,
            //               status:res.status+"55"||"00",
            //               statusText: res.error||"Ocurrió un error",
            //           })
            //           )
            .then((res)=> res.json())
            .then((data)=>data)
            .catch((err)=>err)
            //finally(()=> setLoading(false))
            ;
    };

    
    const get = (url, options ={})=> customFetch(url, options);

    const post = (url, options ={})=> {
        options.method = "POST"
        console.log('Begin post');
        return customFetch(url, options);
    };

    const put = (url, options ={})=> {
        options.method = "PUT"
        return customFetch(url, options);
    };

    const del = (url, options ={})=> {
        options.method = "DELETE"
        return customFetch(url, options);
    };

    return{
        get,
        post,
        put,
        del,      
    };
};
