import { React, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Grid,
  Paper,
  Avatar,
  TextField,
  Button,
  Typography,
  Link,
  Select,
  MenuItem,
  AppBar,
} from "@mui/material";
import { DatePicker, DateField } from "@mui/x-date-pickers";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import useSignUpForm from "../Hooks/UseSignUpForm";
import { helpHttp } from "../helpers/helpHttp";

import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';

import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { Loader } from './Loader';
import { Message } from "./Message";


const initialForm = {
  "nit": process.env.REACT_APP_NIT_EMPRESA,
  empresa: process.env.REACT_APP_ID_EMPRESA,
  tipoDocumento: "1",
  "numeroDocumento":"",
  "nombre":"",
  "apellido":"",
  "telefono":"",
  "email":"",
  "usuario":"",
  "contrasena":"",
  //codigoReferidor: 5,
};



let errorForm =null;
let okForm = null;


//const validationsForm = (form) => {};

const SignUp = () => {
  
  const params = useParams();

  const [touchedFields, setTouchedFields] = useState({});
  
  const [dbDocumentTypes, setDbDocumentTypes] = useState([]);
  const [dataToEdit, setDataToEdit] = useState(null);

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClick = (fieldName) => {
    setTouchedFields((prev) => ({
      ...prev,
      [fieldName]: true,
    }));
  };

  //Llamdo al api
  let api = helpHttp();
  let url = process.env.REACT_APP_DOC_TYPE_SERVICE;

  useEffect(() => {
    const dbDefaultDocumentTypes=[
      {
        abreviatura: "",
        codigo: -1,
        descripcion: ""
    
      }
    ];
    //obtiene los tipos de documento
     api.get(url + process.env.REACT_APP_LIST_DOC_TYPE_SERVICE)
       .then((res) => {
         console.log("useeffect:");
         if (!res.err) {
           console.log("res: " + Object.keys(res).length);
           if (Object.keys(res).length != 0){
            setDbDocumentTypes(res);
            console.log("dbDocumentTypes: " + Object.keys(dbDocumentTypes).length);
            console.log("res: ",res);
            console.log("res.err: " + res.err);
           }
           else{
            console.log("res: ",res);
            setDbDocumentTypes(dbDefaultDocumentTypes);
           }
           
         } else {
           //setDbDocumentTypes(null);
           console.log("res: ",res);
           setDbDocumentTypes(dbDefaultDocumentTypes);

         }
       })
       ;

    setDbDocumentTypes(dbDefaultDocumentTypes);
    console.log("dbDocumentTypes: " + Object.keys(dbDocumentTypes).length);
       
    console.log('empresa: '+ params.empresa+' referido: '+ params.referido );
    if(params.empresa){
      initialForm.nit =  params.empresa ;
    }
    
    if (params.referido){
      initialForm.codigoReferidor =  params.referido;
    }

  }, [url]);

   const validationsForm = (form) => {
    let errors = {};
    console.log("error validationsForm");

    //  if (!form.nit.trim()) {
    //    errors.nit = "El campo Empresa es requerido";
    //  }
    if (touchedFields.nombre && !form.nombre.trim()) {
      errors.nombre = "El campo Nombre es requerido";
    }
    if (touchedFields.apellido && !form.apellido.trim()) {
      errors.apellido = "El campo Apellido es requerido";
    }
    // if (!form.tipoDocumento.trim()) {
    //   errors.tipoDocumento = "El campo Tipo de documento es requerido";
    // }
    if (touchedFields.numeroDocumento && !form.numeroDocumento.trim()) {
      errors.numeroDocumento = "El campo Documento identidad es requerido";
    }
    if (touchedFields.usuario && !form.usuario.trim()) {
      errors.usuario = "El campo Usuario es requerido";
    }
    if (touchedFields.contrasena && !form.contrasena.trim()) {
      errors.contrasena = "El campo Contraseña es requerido";
    }
    if (touchedFields.telefono && !form.telefono.trim()) {
      errors.telefono = "El campo celular es requerido";
    }
    if (touchedFields.email && !form.email.trim()) {
      errors.email = "El campo Correo es requerido";
    }
    

    return errors;
  };

  const errorStyle = {
    color: '#ff0000', // Cambia este valor al color deseado
    fontSize: '10px', // Cambia este valor al tamaño de fuente deseado
    fontWeight: 'bold', // Cambia este valor al peso de fuente deseado
  };
  

  const validationApi = (res) => {
    let errorApi = res;
    
    console.log("error validationApi");
    console.log("errorApi: "+errorApi.statusText);

    

    if(res.status != 0){
      console.log("error en validationApi: ", res);
      errorForm = res;   
    }else{
      console.log("ok en validationApi:", res);
      //errorForm = res; 
      //okForm = "Usuario creado con éxito ";
      okForm = res;
    }

    console.log("fin error validationApi");

    return errorApi;
  };
  

  const {
    form,
    errors,
    errorApi,
    loading,
    response,
    handleChange,
    handleBlur,
    handleSubmit,
  } = useSignUpForm(initialForm, validationsForm, validationApi);

  const paperStyle = {
    padding: 20,
    width: '80%', // Ancho del cuadro, puedes ajustarlo según tus necesidades
    maxWidth: 300, // Ancho máximo del cuadro
    margin: '20px auto',
  };
  const avatarStyle = { backgroundColor: "#1bbd7e" };
  const btnstyle = { margin: "8px 0" };

  return (
    
    <Grid container justifyContent="center" alignItems="center" style={{ minHeight: '100vh' }}>
    
      {loading && <Loader/>} 
      {/* {errorApi && <Message msg={`Error ${errorApi.status}:${errorApi.statusText}`} bgColor="#dc3545"/>}   */}
      {!okForm && errorForm && <Message msg={`Error ${errorForm.status}:${errorForm.message} `} bgColor= {process.env.REACT_APP_APPBAR_COLOR}/>}

      {okForm && !errorForm && <Message msg={`${okForm.descripcion}`} bgColor= {process.env.REACT_APP_APPBAR_COLOR}/>}
      {/* {okForm && !errorForm && <MessageApi error={errorForm} success={okForm} />} */}
      
     
      {!errorForm && !okForm && ( 
      <Paper elevation={10} style={paperStyle}>
        
        <Grid align="center" color={process.env.REACT_APP_APPBAR_COLOR}>
          <h2>Crear nuevo usuario</h2>
        </Grid>
        <TextField
          label="Nombre"
          onBlur={handleBlur}
          onChange={handleChange}
          onClick={() => handleClick("nombre")}
          value={form.nombre}
          placeholder="Ingresa tu nombre"
          variant="outlined"
          name="nombre"
          fullWidth
          required
        />
        {touchedFields.nombre && errors.nombre && <p style={errorStyle}>El campo nombre es requerido</p>}
        <p></p>
        <TextField
          label="Apellido"
          onBlur={handleBlur}
          onChange={handleChange}
          onClick={() => handleClick("apellido")}
          value={form.apellido}
          placeholder="Ingresa tu apellido"
          variant="outlined"
          name="apellido"
          fullWidth
          required
        />
        {touchedFields.apellido && errors.apellido && <p style={errorStyle}>El campo apellido es requerido</p>}
        <p></p>
        <TextField
          select
          SelectProps={{
            native: true,
          }}
          defaultValue=" "
          label="Tipo de documento"
          onBlur={handleBlur}
          onChange={handleChange}
          onClick={() => handleClick("tipoDocumento")}
          value={form.tipoDocumento}
          placeholder="Ingresa el tipo de documento"
          variant="outlined"
          name="tipoDocumento"
          fullWidth
          required
        >
          {dbDocumentTypes.map((option) => (
            <option key={option.codigo} value={option.codigo}>
              {option.descripcion}
            </option>
          ))}
        </TextField>
        {touchedFields.tipoDocumento && errors.tipoDocumento && <p style={errorStyle}>El campo Tipo de documento es requerido</p>}
        <p></p>
        <TextField
          type="number"
          label="Documento identidad"
          onBlur={handleBlur}
          onChange={handleChange}
          onClick={() => handleClick("numeroDocumento")}
          value={form.numeroDocumento}
          placeholder="Ingresa tu documento de identidad"
          variant="outlined"
          name="numeroDocumento"
          fullWidth
          required
        />
        <p></p>
        {touchedFields.numeroDocumento && errors.numeroDocumento && <p style={errorStyle}>El campo Documento identidad es requerido</p>}
        
        <TextField
          label="Usuario"
          onBlur={handleBlur}
          onChange={handleChange}
          onClick={() => handleClick("usuario")}
          value={form.usuario}
          placeholder="Ingresa tu usuario"
          variant="outlined"
          name="usuario"
          fullWidth
          required
        />
        {touchedFields.usuario && errors.usuario && <p style={errorStyle}>El campo Usuario es requerido</p>}
        <p></p>
        <FormControl 
          fullWidth 
          variant="outlined"          
          >
          <InputLabel required htmlFor="outlined-adornment-password">Contraseña</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={showPassword ? 'text' : 'password'}
            onBlur={handleBlur}
            onChange={handleChange}
            onClick={() => handleClick("contrasena")}
            name="contrasena"
            placeholder="Ingresa tu contraseña"
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Password"
          />
        </FormControl>
        {touchedFields.contrasena && errors.contrasena && <p style={errorStyle}>El campo Contraseña es requerido</p>}
        <p></p>
        <TextField
          type="tel"
          label="Celular"
          onBlur={handleBlur}
          onChange={handleChange}
          onClick={() => handleClick("telefono")}
          value={form.telefono}
          placeholder="Ingresa tu número de telefono"
          variant="outlined"
          name="telefono"
          fullWidth
          required
        />
        {touchedFields.telefono && errors.telefono && <p style={errorStyle}>El campo Celular es requerido</p>}
        <p></p>
        <TextField
          label="Correo"
          type="email"
          onBlur={handleBlur}
          onChange={handleChange}
          onClick={() => handleClick("email")}
          value={form.email}
          placeholder="Ingresa tu correo"
          variant="outlined"
          name="email"
          fullWidth
          required
        />
        {touchedFields.email && errors.email && <p style={errorStyle}>El campo Correo es requerido</p>}
        <p></p>
        
        <Button
          type="submit"
          color="primary"
          variant="contained"
          style={btnstyle}
          onClick={handleSubmit}
          fullWidth
          sx={{
            mt: 2,
            mb: 2,
            width: "50%",
            backgroundColor: process.env.REACT_APP_APPBAR_COLOR, // Cambia este valor al color deseado
            "&:hover": {
              backgroundColor: "#cc0000", // Cambia este valor al color deseado para el estado hover
            },
          }}
        >
          Enviar
        </Button>
        
      </Paper>)}
    </Grid>
  );
};

export default SignUp;
