import * as React from "react";
import AppBar from "@mui/material/AppBar";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import { useNavigate } from 'react-router-dom';
import Button from "@mui/material/Button"; 
import { useEffect, useState } from "react";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

//export default function Appbar(props) {
const Appbar = ({ menuOptions, onMenuClick }) => {
    const navigate = useNavigate();

    // Verificar si hay un token almacenado en sessionStorage al cargar la aplicación
    const sessionData = JSON.parse(sessionStorage.getItem('sessionData')) || {};
    //console.log('sessionData = ',sessionData );
    const isAuthenticated = sessionData && sessionData.codigo === 0; // Ajusta según la estructura de tu respuesta


    const handleLogoutClick = () => {
      sessionStorage.removeItem('sessionData');
      //handleLogout(); // Función para cerrar sesión
      //navigate.push(process.env.REACT_APP_SERVER+'login'); // Redirigir a la página de inicio de sesión después del cierre de sesión
      navigate(process.env.REACT_APP_SERVER+'Login');
    };
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = (label) => {
    setDrawerOpen(false);
  
    // Verifica si onMenuClick es una función antes de llamarla
    if (typeof onMenuClick === 'function') {
      onMenuClick(label);
    }
  };
  

  const logoStyle = {
    width: "100px",
    height: "auto",
    margin: "auto",
    display: "block",
    borderRadius: "50%",  // Agrega esta línea
  };
  

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" sx={{ backgroundColor: process.env.REACT_APP_APPBAR_COLOR }}>
        <Toolbar>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
                // onClick={handleClick}
                onClick={handleDrawerOpen}
              >
                <MenuIcon />
              </IconButton>
              
            </Grid>
            <Grid item sx={{ flexGrow: 1 }}>
              <img
                style={logoStyle}
                alt="Logo"
                src="/images/logo_1.jpeg"
                component={Link}
              />
            </Grid>
            <Grid item>
              {isAuthenticated ? (
                <Button color="inherit" onClick={handleLogoutClick}>
                  Logout
                </Button>
              ) : null}
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      {/* Menú Lateral */}
      {/* <Drawer anchor="left" open={drawerOpen} onClose={handleDrawerClose}>
          <List>
            <ListItem component={Link} to={process.env.REACT_APP_SERVER+'home'} onClick={handleDrawerClose}>
              <ListItemText primary="Home" />
            </ListItem>
            {isAuthenticated ? (
              <ListItem  component={Link} to={process.env.REACT_APP_SERVER + 'Scheduler'} onClick={handleDrawerClose}>
                <ListItemText primary="Agendar cita" />
              </ListItem>                
            ) : null}                
            {!isAuthenticated ? (
              <ListItem  component={Link} to={process.env.REACT_APP_SERVER + 'Login'} onClick={handleDrawerClose}>
                <ListItemText primary="Login" />
              </ListItem>                
            ) : null}
          </List>
        </Drawer> */}
        <Drawer anchor="left" open={drawerOpen} onClose={() => handleDrawerClose('')}>
        <List>
          {menuOptions.map((option) => (
            <ListItem key={option.label} component={Link} to={option.to} onClick={() => handleDrawerClose(option.label)}>
              <ListItemText primary={option.label} />
            </ListItem>
          ))}
        </List>
      </Drawer>
    </Box>
  );
}
export default Appbar;
