import React, { useState } from 'react';
import UserManagement from '../components/UserManagement.js';
import Discount from '../components/Discount';
import Appbar from '../components/Appbar';
import Companies from '../components/Companies.js';
import RefferedByUser from '../components/RefferedByUser.js';
import Appointments from '../components/Appointments.js';
import Box from "@mui/material/Box";

export default function UserManagementPage() {
  const [selectedComponent, setSelectedComponent] = useState('UserManagement');

  // Recuperar el rol del usuario desde sessionStorage
  const sessionData = JSON.parse(sessionStorage.getItem('sessionData')) || {};
  const userRole = sessionData.idRol;

  console.log('UserManagementPage...');

  const menuOptions = [
    { label: 'Usuarios' },
    { label: 'Descuentos' },
    // { label: 'Empresas' },
    { label: 'Referidos' },
    { label: 'Citas' },
    ...(userRole === 2 ? [{ label: 'Empresas' }] : []),
  ];

  const handleMenuClick = (label) => {
    // Actualizar el estado para mostrar el componente correspondiente al hacer clic en el menú
    switch (label) {
      case 'Usuarios':
        setSelectedComponent('UserManagement');
        console.log('Usuarios...');
        break;
      case 'Descuentos':
        setSelectedComponent('Discount');
        console.log('Descuentos...');
        break;
      case 'Empresas':
        setSelectedComponent('Companies');
        console.log('Empresas...');
        break;
      case 'Referidos':
        setSelectedComponent('RefferedByUser');
        console.log('Referidos...');
        break;
      case 'Citas':
        setSelectedComponent('Appointments');
        console.log('Citas...');
        break;
      // Agrega más casos según sea necesario para otros elementos del menú
      default:
        break;
    }
  };

  return (
    <>
        <Appbar menuOptions={menuOptions} onMenuClick={handleMenuClick} />
        {selectedComponent === 'UserManagement' && <UserManagement />}
        {selectedComponent === 'Discount' && <Discount />}
        {selectedComponent === 'Companies' && <Companies />}
        {selectedComponent === 'RefferedByUser' && <RefferedByUser />}
        {selectedComponent === 'Appointments' && <Appointments />}
        {/* Agrega más componentes según sea necesario */}
  
      
    </>
  );
}
