import './App.css';
import RefferalApp from './components/RefferalApp';
import { BrowserRouter, HashRouter} from 'react-router-dom';

function App() {
  return (
    <HashRouter> 
      <RefferalApp/> 
    </HashRouter>
     
  );
}

export default App;
