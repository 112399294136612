import React, { useState, useEffect } from 'react';
import { TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Typography, Drawer, Container, Switch } from '@mui/material';
import { helpHttp } from "../helpers/helpHttp";

const Appointments = () => {
  const [users, setUsers] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [appointments, setAppointments] = useState([]);
  const [appointmentDetails, setAppointmentDetails] = useState([]);

  const handleDrawerOpen = async (userId) => {

    //const sessionData = JSON.parse(sessionStorage.getItem('sessionData')) || {};
    console.log('session:',sessionData);
    setDrawerOpen(true);
    setSelectedUserId(userId);
    console.log('userId: '+userId, userId)

    try {
      const apiResponse = await helpHttp().get(`${process.env.REACT_APP_COMPANIE_APPOINTMENTS_DETAIL_SERVICE}?empresa=${sessionData.idEmpresa}&documento=${userId}`);
      if (!apiResponse.err) {
        setAppointments(apiResponse);
        console.log("Detail API Response:", apiResponse);
      }
    } catch (error) {
      console.error("Error fetching appointment details:", error);
    }
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
    setSelectedUserId(null);
    setAppointments([]);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const sessionData = JSON.parse(sessionStorage.getItem('sessionData')) || {};

  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiResponse = await helpHttp().get(`${process.env.REACT_APP_COMPANIE_APPOINTMENTS_SERVICE}?empresa=${sessionData.idEmpresa}`);
        if (!apiResponse.err) {
          setUsers(apiResponse);
          console.log("Appointments API Response:", apiResponse);
        }
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchData();

    const fetchDataDetail = async () => {
      try {
        const apiResponse = await helpHttp().get(`${process.env.REACT_APP_COMPANIE_APPOINTMENTS_SERVICE}?empresa=${sessionData.idEmpresa}`);
        if (!apiResponse.err) {
          setUsers(apiResponse);
          console.log("Appointments API Response:", apiResponse);
        }
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchDataDetail();
  }, []);

  const tableHeader = {
    backgroundColor: process.env.REACT_APP_APPBAR_COLOR,
    color: 'white',
    padding: '12px',
    textAlign: 'left',
  };

  const tableCell = {
    padding: '8px',
    textAlign: 'left',
  };

  const filteredUsers = users.filter((user) =>
    user["Identificación"].toString().toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Container>
      <div style={{ display: 'flex' }}>
        <div style={{ flexGrow: 1, padding: '20px' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
            <Typography variant="h4">Panel de Administración de citas</Typography>
            <div>
              <TextField
                label="Buscar por identificación"
                variant="outlined"
                size="small"
                value={searchTerm}
                onChange={handleSearch}
              />
            </div>
          </div>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  {users.length > 0 && Object.keys(users[0]).map((columnName) => (
                    <TableCell key={columnName} style={tableHeader}>
                      {columnName}
                    </TableCell>
                  ))}
                  <TableCell style={tableHeader}>Acciones</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredUsers.map((user) => (
                  <TableRow key={user["Codigo Cita"]}>
                    {Object.keys(user).map((columnName) => (
                      <TableCell key={columnName} style={tableCell}>
                        {user[columnName]}
                      </TableCell>
                    ))}
                    <TableCell style={tableCell}>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => handleDrawerOpen(user["Identificación"])}
                      >
                        Ver Citas
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerClose}>
          <div style={{ width: '90vw', padding: 20, margin: 'auto' }}>
            {selectedUserId && (
              <div>
                <Typography variant="h4">Citas</Typography>
                <TableContainer component={Paper} style={{ marginBottom: '20px' }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        {appointments.length > 0 && Object.keys(appointments[0]).map((columnName) => (
                          <TableCell key={columnName} style={tableHeader}>
                            {columnName}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {appointments.map((appointment, index) => (
                        <TableRow key={index}>
                          {Object.keys(appointment).map((columnName) => (
                            <TableCell key={columnName} style={tableCell}>
                              {appointment[columnName]}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            )}
          </div>
        </Drawer>
      </div>
    </Container>
  );
};

export default Appointments;
