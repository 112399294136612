import React, { useState } from 'react';
import { Button, TextField, Typography } from '@mui/material';

const AddCompany = ({ onAddCompany, onCancel }) => {
  const [newCompany, setNewCompany] = useState({
    id: '',
    nit: '',
    nombre: '',
    nombreencargado: '',
    celular: '',
    correo: '',
    estado: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewCompany((prevCompany) => ({ ...prevCompany, [name]: value }));
  };

  const handleAddCompany = () => {
    // Validar que todos los campos estén llenos
    const isAnyFieldEmpty = Object.values(newCompany).some((value) => value.trim() === '');

    if (isAnyFieldEmpty) {
      alert('Por favor, complete todos los campos.');
      return;
    }

    onAddCompany(newCompany);
    setNewCompany({
      id: '',
      nit: '',
      nombre: '',
      nombreencargado: '',
      celular: '',
      correo: '',
      estado: '',
    });
  };

  return (
    <div>
      <Typography variant="h5" gutterBottom>
        Agregar Empresa
      </Typography>
      <TextField label="ID" name="id" value={newCompany.id} onChange={handleInputChange} fullWidth margin="normal" />
      <TextField label="Nit" name="nit" value={newCompany.nit} onChange={handleInputChange} fullWidth margin="normal" />
      <TextField label="Nombre" name="nombre" value={newCompany.nombre} onChange={handleInputChange} fullWidth margin="normal" />
      <TextField label="Nombre Encargado" name="nombreencargado" value={newCompany.nombreencargado} onChange={handleInputChange} fullWidth margin="normal" />
      <TextField label="Celular" name="celular" value={newCompany.celular} onChange={handleInputChange} fullWidth margin="normal" />
      <TextField label="Correo" name="correo" value={newCompany.correo} onChange={handleInputChange} fullWidth margin="normal" />
      <TextField label="Estado" name="estado" value={newCompany.estado} onChange={handleInputChange} fullWidth margin="normal" />
      <Button variant="contained" color="success" onClick={handleAddCompany}>
        Agregar
      </Button>
      <Button variant="outlined" color="error" onClick={onCancel}>
        Cancelar
      </Button>
    </div>
  );
};

export default AddCompany;
