import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Container, Grid, Paper, TextField, Button, Typography } from "@mui/material";
import useSchedulerForm from "../Hooks/UseSchedulerForm";
import { helpHttp } from "../helpers/helpHttp";
import { Loader } from "./Loader";
import { Message } from "./Message";
import Appbar from "./Appbar";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "moment/locale/es";

const localizer = momentLocalizer(moment);

const initialForm = {
  empresa: "",
  tipocita: "1",
  persona: "",
  codReferido: "",
  fechaHoraCita: "",
  nombrePersona: "",
  nombreEmpresa: "",
  idUsuario: "",
};

let errorForm = null;
let okForm = null;

const validationsForm = (form) => {
  let errors = {};
  console.log("error validationsForm");

  if (!form.tipocita.trim()) {
    errors.tipocita = "El campo tipo cita es requerido";
  }

  if (!form.fechaHoraCita.trim()) {
    errors.fechaHoraCita = "El campo fecha es requerido";
  }

  return errors;
};

/// Función para convertir cadena de tiempo a objeto Date y ajustar horas
const timeStringToDate = (timeString, adjustHours = 0) => {
  const [hours, minutes] = timeString.split(':').map(Number);
  console.log('horas: '+ hours);
  console.log('minutes: '+ minutes);
  const date = new Date(1970, 1, 1, hours, 0,0); 
  date.setHours(date.getHours() + adjustHours);
  return date;
};

// Tiempos de ejemplo
let officeMinTime = "";
let officeMaxTime = 11;

const Scheduler = () => {
  const params = useParams();
  const [dbAppointmentType, setDbAppointmentType] = useState([]);
  const [dbAvailableEvents, setdbAvailableEvents] = useState([]);
  const [availableDates, setAvailableDates] = useState([]);

  let api = helpHttp();
  let url = process.env.REACT_APP_APPOINT_TYPE_SERVICE;

  const [apiCalled, setApiCalled] = useState(false);  

  

  useEffect(() => {
    const fetchData = async () => {
      try {
        const sessionData = JSON.parse(sessionStorage.getItem("sessionData")) || {};
        initialForm.empresa = sessionData.idEmpresa;
        initialForm.persona = sessionData.idPersona;
        initialForm.nombrePersona = sessionData.nombre;
        initialForm.apellido = sessionData.apellido;
        initialForm.nombreEmpresa = sessionData.nombreEmpresa;
        initialForm.idUsuario = sessionData.idUsuario;
  
        // OBTIENE LISTA DE TIPO DE CITAS
        const appointmentTypeRes = await api.get(url + process.env.REACT_APP_LIST_APPOINT_SERVICE);
        if (!appointmentTypeRes.err) {
          setDbAppointmentType(appointmentTypeRes);
        } else {
          setDbAppointmentType(null);
        }

        // OBTIENE HORARIO EMPRESA
        const officeHours = await api.get( process.env.REACT_APP_OFFICE_HOURS_SERVICE + '?idEmpresa='+sessionData.idEmpresa);
        if (!officeHours.err) {
          //setDbAppointmentType(officeHours);
          console.log("Horario: ", officeHours);
          // Convertir cadenas de texto a objetos Date
          officeMinTime = timeStringToDate(officeHours.startTime, -1);
          const [hour, minutes] = officeHours.endTime.split(':').map(Number);
          officeMaxTime = hour+2;
          
          console.log("17-->: ", new Date(1970, 1, 1, 17, 0, 0));
          console.log("18-->: ", officeMaxTime);
          console.log("officeMaxTime es un objeto Date: ", officeMaxTime instanceof Date);
          

        } else {
          //setDbAppointmentType(null);
          console.log("Error: ", officeHours);
        }

        
  
        // OBTIENE JSON CON FECHAS DISPONIBLES PARA AGENDAR
        const availableEventsRes = await api.get(process.env.REACT_APP_CALENDAR + process.env.REACT_APP_AVAILABLE_EVENTS + '?idEmpresa='+sessionData.idEmpresa);
        if (!availableEventsRes.err) {
          console.log("agenda funcionando", availableEventsRes);
          setdbAvailableEvents(availableEventsRes);
          
          // Función para redondear al minuto más cercano (:00 o :30)
          const roundToNearest30Minutes = (dateTimeString) => {
            const dateTime = moment(dateTimeString, "DD-MM-YYYY HH:mm");
            const remainder = dateTime.minute() % 29;
            //const adjustedDateTime = moment(dateTime).startOf("hour");

            if (remainder >= 15) {
              //adjustedDateTime.add(30, "minutes");
              //console.log("el 15: ", dateTimeString);
            } else if (remainder === 0) { // Agregar esta condición para no redondear si los minutos son 0
              //adjustedDateTime.add(30, "minutes");
              //adjustedDateTime = moment(dateTimeString, "DD-MM-YYYY HH:mm");
              //console.log("el error: ", dateTimeString);
            }

            //return adjustedDateTime.format("DD-MM-YYYY HH:mm");
            return dateTime.format("DD-MM-YYYY HH:mm");
          };
          // Verifica si availableEventsRes es un array antes de mapearlo
          const adjustedEvents = Array.isArray(availableEventsRes)?   
          
            
            // Configurar las fechas y horas disponibles en rangos de 30 minutos
              availableEventsRes.map((event) => {


              //console.log(`Tiempo de inicio antes: ${event.freeStartTime}`);
              //console.log(`Tiempo de fin antes : ${event.freeEndTime}`);

              const adjustedStartTime = roundToNearest30Minutes(event.freeStartTime);
              const adjustedEndTime = roundToNearest30Minutes(event.freeEndTime);
              

              //console.log(`Evento: ${event.title}`);
              //console.log(`Tiempo de inicio: ${adjustedStartTime}`);
              //console.log(`Tiempo de fin: ${adjustedEndTime}`);

              return {
                ...event,
                freeStartTime: adjustedStartTime,
                freeEndTime: adjustedEndTime,
              };
            })
            :[];
  
          // Configurar las fechas y horas disponibles en rangos de 30 minutos
          const availableTimeSlots = adjustedEvents;
          console.log("timeslots: " + Object.keys(availableEventsRes).length);
          const formattedEvents = availableTimeSlots.flatMap((slot, index) => {
            const startTime = moment(slot.freeStartTime, "DD-MM-YYYY HH:mm");
            const endTime = moment(slot.freeEndTime, "DD-MM-YYYY HH:mm");
  
            const events = [];
            while (startTime < endTime) {
              events.push({
                id: index + startTime.format("HH:mm"),
                title: "Disponible",
                start: new Date(startTime),
                end: new Date(startTime.add(30, "minutes")),
              });
            }
  
            return events;
          });
  
          setAvailableDates(formattedEvents);
          setApiCalled(true); // Marcar que el llamado al API ya se ha realizado
        } else {
          console.log("agenda nula");
          setdbAvailableEvents([]);
        }
      } catch (error) {
        console.error("Error en el efecto:", error);
      }
    };
  
    // Solo ejecutar fetchData si el API no se ha llamado aún
    if (!apiCalled) {
      fetchData();
    }
  }, [url, apiCalled]);
  

  const validationApi = (res) => {
    let errorApi = res;

    console.log("error validationApi");
    console.log("errorApi: " + errorApi.statusText);

    if (res.status !== 0) {
      errorForm = res;
    } else {
      okForm = "Cita creada con éxito ";
    }

    console.log("fin error validationApi");

    return errorApi;
  };

  const {
    form,
    errors,
    loading,
    response,
    handleChange,
    handleBlur,
    handleSubmit,
  } = useSchedulerForm(initialForm, validationsForm, validationApi);

  const paperStyle = {
    padding: 20,
    margin: "20px auto",
  };

  const handleSelectEvent = (event) => {
    const formattedDate = moment(event.start).format("YYYY-MM-DDTHH:mm");
    handleChange({ target: { name: "fechaHoraCita", value: formattedDate } });
  };

  const menuOptions = [
    { label: "Agendar cita", to: process.env.REACT_APP_SERVER + 'Scheduler' }
  ];

  return (
    <Container>
      <Appbar menuOptions={menuOptions}/>
      {loading && <Loader />}
      {!okForm && errorForm && (
        <Message msg={`Error ${errorForm.status}:${errorForm.message} `} bgColor= {process.env.REACT_APP_APPBAR_COLOR} />
      )}
      {okForm && !errorForm && <Message msg={`${okForm}`} bgColor= {process.env.REACT_APP_APPBAR_COLOR} />}

      {!errorForm && !okForm && (
        <Paper elevation={10} style={paperStyle}>
          <Grid container direction="column" alignItems="center" justify="center">
            <Typography
              variant="h2"
              style={{ fontSize: "3em", color: process.env.REACT_APP_APPBAR_COLOR }}
            >
              {" "}
              Bienvenido
            </Typography>
            <Typography variant="h2" style={{ fontSize: "1em" }}>
              {` ${initialForm.nombrePersona} ${initialForm.apellido}`}
            </Typography>
            <Typography variant="h2" style={{ fontSize: "1em" }}>
              {`En este momento te gustaría agendar tu cita de:`}
            </Typography>

            <TextField
              select
              SelectProps={{
                native: true,
              }}
              defaultValue=" "
              label="Tipo de cita"
              onBlur={handleBlur}
              onChange={handleChange}
              value={form.tipocita}
              placeholder="Ingresa el tipo de cita"
              variant="outlined"
              name="tipocita"
              fullWidth
              required
              sx={{ mt: 2, mb: 2, width: "50%" }}
            >
              {dbAppointmentType.map((option) => (
                <option key={option.codigo} value={option.codigo}>
                  {option.nombre}
                </option>
              ))}
            </TextField>

            <Typography variant="h2" style={{ fontSize: "1em" }}>
              {`Selecciona la fecha en la que te gustaría agendar tu cita`}
            </Typography>

            {/* <TextField
              type="datetime-local"
              label=""
              onBlur={handleBlur}
              onChange={handleChange}
              value={form.fechaHoraCita}
              placeholder="Ingresa la fecha de tu cita"
              variant="outlined"
              name="fechaHoraCita"
              fullWidth
              required
              sx={{ mt: 2, mb: 2, width: "50%" }}
            /> */}
            {/* <div style={{ marginTop: "20px" }}>
              <div style={{ border: "1px solid #ccc", borderRadius: "5px", padding: "10px" }}>       */}
            <div style={{ maxWidth: '100%', overflowX: 'auto', marginTop: '20px' }}>
              <div style={{ border: "1px solid #ccc", borderRadius: "5px", padding: "10px", maxWidth: '100%', overflowX: 'auto' }}>      
   
                <Calendar
                  localizer={localizer}
                  events={availableDates}
                  startAccessor="start"
                  endAccessor="end"
                  titleAccessor={() => 'Disponible'} // Configurar el título directamente
                  //style={{ height: 500 }}
                  style={{ width: '100%' }}
                  onSelectEvent={handleSelectEvent}
                  views={['week']} // Mostrar solo la vista semanal
                  step={30} // Mostrar intervalos de 30 minutos
                  timeslots={2} // Mostrar dos intervalos por hora (30 minutos cada uno)
                  defaultView={'week'} // Mostrar la vista semanal por defecto
                  min={officeMinTime} // Mostrar desde las 6 AM
                  //max={officeMaxTime} // Mostrar hasta las 5 PM
                  max = {new Date(1970, 1, 1, officeMaxTime, 0, 0)}
                />
              </div>
            </div>
            

            <Button
              type="submit"
              variant="contained"
              onClick={handleSubmit}
              fullWidth
              sx={{
                mt: 2,
                mb: 2,
                width: "50%",
                backgroundColor: process.env.REACT_APP_APPBAR_COLOR,
                "&:hover": {
                  backgroundColor: "#cc0000",
                },
              }}
            >
              Agendar
            </Button>

            <Typography variant="h2" style={{ fontSize: "1em" }}>
              {`Te contactaremos vía Whatsapp ó llamada`}
            </Typography>
          </Grid>
        </Paper>
      )}
    </Container>
  );
};

export default Scheduler;
