import '../App.css'
//import Login from './Login';
import LoginPage from '../pages/LoginPage'
//import Test from '../pages/TestPage'
import Appbar from './Appbar';
import Scheduler from './Scheduler';
import SignUp from './SignUp';
import { useEffect, useState } from 'react';
import {BrowserRouter, Routes, Route} from "react-router-dom"
import Home from '../pages/Home'
import NotFound from '../pages/NotFound'
import SignUpPage from '../pages/SignUpPage'
import { helpHttp } from '../helpers/helpHttp';
import { Loader } from './Loader';
import { Message } from './Message';
import UserManagementPage from '../pages/UserManagement';


export default function RefferalApp(){
  const[homePage, setHomepage] = useState('');
  const [error, seterror] = useState(null);
  const [loading, setloading] = useState(true);
  const [MainPage, setMainPage] = useState(false);

  //const rutaServidor= "http://localhost:3000"; ///Referidos";
  const rutaServidor = process.env.REACT_APP_SERVER;
 
   
  return (
    <div className="App">
      
      {/* {loading && <Loader/>} 
      {error && <Message msg={`Error ${error.status}:${error.statusText}`} bgColor="#dc3545"/>}   */}
      
        <Routes>
          <Route path={rutaServidor} element = {<LoginPage/>}/>
          <Route path={rutaServidor+":empresa"} element = {<LoginPage/>}/>
          {/* <Route path={rutaServidor+"Test"} element = {<Test/>}/> */}
          <Route path={rutaServidor+"Home"} element = {<Home/>}/>
          <Route path={rutaServidor+"Login"} element = {<LoginPage/>}/>
          <Route path={rutaServidor+"Login/:empresa"} element = {<LoginPage/>}/>
          <Route path={rutaServidor+"UserManagement"} element = {<UserManagementPage/>}/>
          <Route path={rutaServidor+"UserManagement/:empresa"} element = {<UserManagementPage/>}/>
          <Route path={rutaServidor+"UserManagement/:empresa/:referido"} element = {<UserManagementPage/>}/>
          {/* <Route path={rutaServidor+"admin/add-user"} element = {<SignUpAdminPage/>}/> */}
          <Route path={rutaServidor+"SignUp/"} element = {<SignUpPage/>}/>
          <Route path={rutaServidor+"SignUp/:empresa"} element = {<SignUpPage/>}/>
          <Route path={rutaServidor+"SignUp/:empresa/:referido"} element = {<SignUpPage/>}/>
          <Route path={rutaServidor+"Scheduler"} element = {<Scheduler/>}/>
          <Route path={rutaServidor+"Scheduler/:idEmpresa/:nombreEmpresa/:idUsuario/:idPersona/:nombre/:apellido"} element = {<Scheduler/>}/>
          <Route path="*" element = {<NotFound/>}/>
          {/* <Route path="*" element = {<LoginPage/>}/> */}
        </Routes>
      
      
      

    </div>
  );
};



