import React, { useState, useEffect } from "react";
import { Grid,Paper, Avatar, TextField, Button, Typography,Link } from '@mui/material'
import { useParams } from "react-router-dom";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { helpHttp } from '../helpers/helpHttp';
import useLoginForm from '../Hooks/UseLoginForm';
import { Loader } from './Loader';
import { Message } from './Message';



const rutaServidorPrd = process.env.REACT_APP_SERVER;

// const initialForm = {
//     empresa:1234,
//     usuario:"",
//     contrasena:"",    
//   };

let errorForm =null;
let okForm = null;

const Login=()=>{

    // const [initialForm, setInitialForm] = useState({
    //     empresa: '1234',
    //     usuario: '',
    //     contrasena: '',
    // });

    const params = useParams();

    let paramEmpresa = '';

    
    if (params.empresa) {
        paramEmpresa = params.empresa;
        console.log("Empresa url:", paramEmpresa);
    }else{
        paramEmpresa = '1234'; 
        console.log("empresa inicial:", paramEmpresa); 
    }

     const initialForm = {
            empresa:paramEmpresa,
            usuario:"",
            contrasena:"",    
        };

    

    //console.log("Initial Form:", initialForm);

    const validationsForm = (form) => {
        let errors = {};
        console.log("error validationsForm");
    
        // if (!form.nit.trim()) {
        //   errors.nit = "El campo Empresa es requerido";
        // }
        return errors;
      };
    
      
    
      const validationApi = (res) => {
        let errorApi = res;
        
        console.log("validando api validationApi");
        console.log("errorApi: "+errorApi.statusText);
        console.log("respuesta del api: "+res);
    
        //errorForm = res;
        if (res.status !== 0) {
            errorForm = res;
            console.log("res error: "+res);
          } else {
            okForm = res;
            console.log("res okform: "+res);
          }
      

    
        console.log("fin error validationApi");
    
        return errorApi;
      };

      
      
    
    const {
        form,
        errors,
        errorApi,
        loading,
        response,
        handleChange,
        handleBlur,
        handleSubmit,
      } = useLoginForm(initialForm, validationsForm, validationApi);

    //const paperStyle={padding :20,height:'70vh',width:280, margin:"20px auto"}
    //const paperStyle = { padding: 20, width: 280, margin: '20px auto' };
    const paperStyle = {
        padding: 20,
        width: '80%', // Ancho del cuadro, puedes ajustarlo según tus necesidades
        maxWidth: 300, // Ancho máximo del cuadro
        margin: '20px auto',
      };
    const avatarStyle={backgroundColor:'#1bbd7e'}
    const btnstyle={margin:'8px 0'}
    return(
        <Grid container justifyContent="center" alignItems="center" style={{ minHeight: '100vh' }}>
            {loading && <Loader/>} 
            {!okForm && errorForm && <Message msg={`${errorForm.message} `} bgColor= {process.env.REACT_APP_APPBAR_COLOR} />}

            {okForm && !errorForm && <Message msg={`${okForm}`} bgColor= {process.env.REACT_APP_APPBAR_COLOR} />}
      
            {!errorForm && !okForm && (
                <Paper elevation={10} style={paperStyle}>
                    {/* <Grid align='center'>
                        <Avatar style={avatarStyle}><LockOutlinedIcon/></Avatar>
                        <h2>Sign In</h2>
                    </Grid> */}
                    <div style={{ textAlign: 'center' }}>
                        <img
                            src="/images/logo_1.jpeg"
                            alt="Avatar"
                            style={{ width: 200, height: 200, borderRadius: '50%', marginBottom: 10 }}
                        />
                        {/* <Typography variant="h5">Sign In</Typography> */}
                    </div>
                    {/* <TextField 
                        label='Empresa' placeholder='Ingrese la empresa' 
                        variant="outlined" fullWidth required
                        onBlur={handleBlur} onChange={handleChange}
                        name="empresa"    
                    /> */}
                    <TextField 
                        label='Usuario' 
                        placeholder='Ingrese su usuario' 
                        variant="outlined" fullWidth aria-required
                        onBlur={handleBlur} onChange={handleChange}
                        name="usuario"
                    />
                    <p></p>
                    <TextField 
                        label='Contraseña' 
                        placeholder='Ingrese su contraseña' 
                        type='password' variant="outlined" fullWidth required
                        onBlur={handleBlur} onChange={handleChange}
                        name="contrasena"    
                    />
                    <p></p>
                    <Button 
                        type='submit' color='primary' 
                        variant="contained" style={{ ...btnstyle, backgroundColor: process.env.REACT_APP_APPBAR_COLOR }}
                        fullWidth
                        onClick={handleSubmit}
                    >Log In</Button>
                    {/* <Typography >
                        <Link href="#" >
                            Forgot password ?
                    </Link>
                    </Typography> */}
                    
                    <Typography > 
                        {/* <Link href={rutaServidorPrd+"SignUp/"} > */}
                        <Link href={`#${rutaServidorPrd}SignUp/`}>
                            Crear nuevo usuario
                    </Link>
                    </Typography>
                    

                </Paper>
                
            )}
            {/* Agregar la versión del sitio web */}
            <Typography style={{ position: 'absolute', bottom: 10, right: 10 }}>
                Version: {process.env.REACT_APP_VERSION}
            </Typography>
        </Grid>
        
    )
}

export default Login