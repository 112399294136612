import { useState } from "react"
import { helpHttp } from '../helpers/helpHttp';

export const useSchedulerForm = (initialForm, validateForm, validationApi) =>{
    const[form, setForm] = useState(initialForm);
    const[errors, setErrors] = useState({});
    const[errorApi, setErrorApi] = useState(null);
    const[loading, setLoading] = useState(false);
    const[response, setResponse] = useState(null);

    const handleChange =(e)=>{
         const{name, value} = e.target;
        
        setForm({
            ...form,
            [name]:value, 
        })

    };

    const handleBlur =(e)=>{};

    const handleSubmit =(e)=>{
        console.log('enviando useSchedulerForm...')
        e.preventDefault();

        setErrors(validateForm(form));

        console.log("errors lenght: "+Object.keys(errors).length );


        console.log('enviando2...')

        if(Object.keys(errors).length === 0){
            //alert("Enviando Formulario");
            console.log('Enviando Formulario...')
            //Envia los datos 
            setLoading(true);
            //Llamdo al api
            let api = helpHttp();
            let url = process.env.REACT_APP_SCHEDULER_SERVICE;

            let options ={
                     body:form,
                     headers:{"content-type":"application/json"},
                   };
            api.post(url+process.env.REACT_APP_CREATE_APPOINT_SERVICE,options)
                .then((res)=>{
                    console.log('Llamando api post: '+res.message) 
                    if(res.ok){
                        //setDb([...dbUsers,form])
                        alert("Datos enviados con exito");
                        //borrar
                        //setErrorApi(res); 
                     }else{
                        //setDb(res);
                        //alert("Datos enviados SIN exito");
                        setErrorApi(null);
                        if(res.descripcion){
                            res.status = res.codigo;
                            res.message= res.descripcion;
                        }
                        setErrorApi(validationApi(res));
                        console.log('mesaje error errorApi2:'+errorApi);
                    } 
                    setLoading(false);
                    
                  }                 
                
                  ) 
        }else{            
            return;
        }
    };

    return{
        form,
        errors,
        loading,
        response,
        handleChange,
        handleBlur,
        handleSubmit};

};

export default useSchedulerForm;