import { useState, useEffect} from "react"
import { helpHttp } from '../helpers/helpHttp';

export const useLoginForm = (initialForm, validateForm, validationApi) =>{
    const[form, setForm] = useState(initialForm);
    const[errors, setErrors] = useState({});
    const[errorApi, setErrorApi] = useState(null);
    const[loading, setLoading] = useState(false);
    const[response, setResponse] = useState(null);

    

    const handleChange =(e)=>{
         const{name, value} = e.target;
        
        setForm({
            ...form,
            [name]:value, 
        })

    };

    const handleBlur =(e)=>{
        console.log("handleblur");
        handleChange(e);
        setErrors(validateForm(form));
    };

    const handleSubmit =(e)=>{
        console.log('enviando...')
        e.preventDefault();

        setErrors(validateForm(form));

        console.log("errors lenght: "+Object.keys(errors).length );

        if(Object.keys(errors).length === 0){
            //alert("Enviando Formulario");
            console.log('Enviando Formulario...')
            //Envia los datos 
            setLoading(true);
            //Llamdo al api
            let api = helpHttp();
            let url = process.env.REACT_APP_LOGIN_SERVICE;

            let options ={
                     body:form,
                     headers:{
                        "content-type":"application/json",
                        // "Origin": "http://localhost:3000"
                        },
                   };
            api.post(url + process.env.REACT_APP_CREATE_LOGIN_SERVICE,options)
                .then((res)=>{
                    console.log('Llamando api post',res) ;
                    
                    if(!res.err){

                        console.log("Token recibido:", res.token);
                        sessionStorage.setItem('token', res.token);

                        console.log("res useLoginForm: " + res.apellido);
                        if (res.codigo == 0) {
                            // Almacenar el token en sessionStorage
                            sessionStorage.setItem('sessionData', JSON.stringify(res));
                            const sessionData = JSON.parse(sessionStorage.getItem('sessionData')) || {};
                            
                            // Si el usuario es administrativo abre la pagina con opciones administrativas
                            // de lo contrario es un usuario común
                            if(res.idRol == process.env.REACT_APP_ADMIN_COMPANY_USER_ROL || 
                                res.idRol == process.env.REACT_APP_ADMIN_USER_ROL){
                                //window.location.replace(`/UserManagement/`);
                                window.location.replace(`#/UserManagement/`);
                            }else{
                                //window.location.replace(`/Scheduler/`);   
                                window.location.replace(`#/Scheduler/`);       
                            }                      

                        }else{
                            setErrorApi(null);
                            if(res.descripcion){
                                res.status = res.codigo;
                                res.message= res.descripcion;
                            }
                            setErrorApi(validationApi(res));
                            console.log('mesaje error errorApi2:'+errorApi);
                        }
                        
                        //borrar
                        //setErrorApi(res); 
                     }else{
                        //setDb(res);
                        alert("Datos enviados SIN exito");
                        setErrorApi(null);
                        setErrorApi(validationApi(res));
                        console.log('mesaje error errorApi2:'+errorApi);
                    } 
                    setLoading(false);
                    
                  }                 
                
                  ) 
                .then((body) => console.log("data:"+body)) 
        }else{            
            return;
        }

    };

    return{
        form,
        errors,
        loading,
        response,
        handleChange,
        handleBlur,
        handleSubmit};

};

export default useLoginForm;