import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Typography, Drawer, Container, TextField } from '@mui/material';
import AddCompany from './AddCompany';
import { helpHttp } from "../helpers/helpHttp";

const Companies = () => {
  const [Users, setUsers] = useState([]);
  const [editingUserId, setEditingUserId] = useState(null);
  const [editedUserData, setEditedUserData] = useState({
    "Codigo": '',
    "Nit": '',
    "Nombre": '',
    "Ciudad": '',
    "Nombre Encargado": '',
    "Celular": '',
    "Correo": '',
    "Estado": '',
  });
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorApi, setErrorApi] = useState(null);
  const [okForm, setOkForm] = useState(null);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
    setEditingUserId(null);
    setEditedUserData({
      "Codigo": '',
      "Nit": '',
      "Nombre": '',
      "Ciudad": '',
      "Nombre Encargado": '',
      "Celular": '',
      "Correo": '',
      "Estado": '',
    });
    setIsEditing(false);
  };

  const handleSaveUser = async (userId, updatedUserData) => {
    try {
      setLoading(true);
      await new Promise((resolve) => setTimeout(resolve, 1000));

      const updatedUsers = Users.map((user) =>
        user['Codigo'] === userId ? { ...user, ...updatedUserData } : user
      );
      setUsers(updatedUsers);

      const requestData = {
        body: updatedUserData,
        headers: { "content-type": "application/json" },
      };

      helpHttp()
        .put(process.env.REACT_APP_COMPANIES_SERVICE + process.env.REACT_APP_UPDATE_COMPANIES_SERVICE, requestData)
        .then((res) => {
          if (res.codigo === 0) {
            setOkForm(res);
          } else {
            setErrorApi(null);
            if (res.descripcion) {
              res.status = res.codigo;
              res.message = res.descripcion;
            }
            setErrorApi(`Error al guardar el usuario: ${res.message}`);
          }
          setLoading(false);
        });
    } catch (error) {
      console.error('Error al guardar el usuario: ', error.message);
    } finally {
      setEditingUserId(null);
      setEditedUserData({
        "Codigo": '',
        "Nit": '',
        "Nombre": '',
        "Ciudad": '',
        "Nombre Encargado": '',
        "Celular": '',
        "Correo": '',
        "Estado": '',
      });
      setIsEditing(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiResponse = await helpHttp().get(process.env.REACT_APP_COMPANIES_SERVICE + process.env.REACT_APP_LIST_COMPANIES_SERVICE);
        if (!apiResponse.err) {
          setUsers(apiResponse);
        }
      } catch (error) {
        console.error("Error en el efecto:", error);
      }
    };

    fetchData();
  }, []);

  const tableHeader = {
    backgroundColor: process.env.REACT_APP_APPBAR_COLOR,
    color: 'white',
    padding: '12px',
    textAlign: 'left',
  };

  const tableCell = {
    padding: '8px',
    textAlign: 'left',
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };
  
  const filteredUsers = Users.filter((user) =>
    user.Nombre && user.Nombre.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Container>
      <div style={{ display: 'flex' }}>
        <div style={{ flexGrow: 1, padding: '20px' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
            <Typography variant="h4">Panel de Administración de empresas</Typography>
            <div>
              <TextField
                label="Buscar por nombre empresa"
                variant="outlined"
                size="small"
                value={searchTerm}
                onChange={handleSearch}
              />
              <Button
                variant="contained"
                color="success"
                style={{ backgroundColor: process.env.REACT_APP_APPBAR_COLOR, marginLeft: '10px' }}
                onClick={handleDrawerOpen}
              >
                Agregar Empresa
              </Button>
            </div>
          </div>
          {Users && Users.length > 0 ? (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    {Object.keys(Users[0]).map((columnName) => (
                      <TableCell key={columnName} style={tableHeader}>
                        {columnName}
                      </TableCell>
                    ))}
                    <TableCell style={tableHeader}>Acciones</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredUsers.map((user) => (
                    <TableRow key={user.Codigo}>
                      {Object.keys(user).map((columnName, index) => (
                        <TableCell key={user.Codigo + "-" + columnName} style={tableCell}>
                          {
                            index < 1 ? (
                              user[columnName]
                            ) : (
                              editingUserId === user.Codigo ? (
                                <TextField
                                  value={editedUserData[columnName]}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    setEditedUserData((prevFields) => ({
                                      ...prevFields,
                                      [columnName]: value,
                                    }));
                                  }}
                                />
                              ) : (
                                user[columnName]
                              )
                            )}
                        </TableCell>
                      ))}
                      <TableCell style={tableCell}>
                        {editingUserId === user.Codigo ? (
                          <>
                            <Button
                              variant="outlined"
                              color="primary"
                              onClick={() => handleSaveUser(user.Codigo, editedUserData)}
                            >
                              Guardar
                            </Button>
                            <Button
                              variant="outlined"
                              color="error"
                              onClick={() => handleDrawerClose()}
                            >
                              Cancelar
                            </Button>
                          </>
                        ) : (
                          <Button
                            variant="outlined"
                            color="primary"
                            disabled={editingUserId !== null}
                            onClick={() => {
                              setEditingUserId(user.Codigo);
                              setEditedUserData(user);
                            }}
                          >
                            Editar
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography variant="body1">Cargando...</Typography>
          )}
        </div>
        <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerClose}>
          <div style={{ width: '80%', maxWidth: 400, padding: 20, margin: 'auto' }}>
            <AddCompany />
          </div>
        </Drawer>
      </div>
    </Container>
  );
};

export default Companies;
