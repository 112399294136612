import SignUp from '../components/SignUp';
import Appbar from "../components/Appbar";

export default function SignUpPage(){
    const menuOptions = [
        { label: "Home", to: process.env.REACT_APP_SERVER + 'Home' },
        { label: "Login", to: process.env.REACT_APP_SERVER + 'Login' }
      ];
    
    return(
        <>
        <Appbar menuOptions={menuOptions}/> 
        <SignUp/>
        </>
    );
}