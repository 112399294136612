import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Typography, Drawer, Container, TextField } from '@mui/material';
import { helpHttp } from "../helpers/helpHttp";
import { Loader } from './Loader';
import { Message } from "./Message";

const Discount = () => {
  const [discounts, setDiscounts] = useState([]);
  const [editingDiscountId, setEditingDiscountId] = useState(null);
  // Estado para almacenar los datos editados de cada campo individualmente
  const [editedFields, setEditedFields] = useState({});
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorApi, setErrorApi] = useState(null);
  const [okForm, setOkForm] = useState(null);
  const [apiData, setApiData] = useState(null);
  const [showMessage, setShowMessage] = useState(false);
  const [keyToUpdate, setKeyToUpdate] = useState(0); 

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
    setEditingDiscountId(null);
    // Limpiar los datos editados al cerrar el drawer
    setEditedFields({});
    setIsEditing(false);
  };

  const handleSaveDiscount = async (discountId, updatedDiscountData) => {
    try {
      setLoading(true);
      await new Promise((resolve) => setTimeout(resolve, 1000));
      console.log('codigo a actualizar: ',discounts)
      const updatedDiscounts = discounts.map((discount) =>
        
        discount.codigo === discountId ? { ...discount, ...updatedDiscountData } : discount
      );
      setDiscounts(updatedDiscounts);

      const requestData = {
        body: updatedDiscountData,
        headers: { "content-type": "application/json" },
      };

      helpHttp()
        .put(process.env.REACT_APP_LEVELS_SERVICE + process.env.REACT_APP_UPDT_LEVELS_SERVICE, requestData)
        .then((res) => {
          if (res.codigo === 0) {
            //alert("Datos enviados con éxito");
            setOkForm(res);
            setShowMessage(true);
          } else {
            setErrorApi(null);
            if (res.descripcion) {
              res.status = res.codigo;
              res.message = res.descripcion;
            }
            setErrorApi(`Error al guardar el descuento: ${res.message}`);
          }
          setLoading(false);
        });

    } catch (error) {
      console.error('Error al guardar el descuento:', error.message);
      setErrorApi(`Error al guardar el descuento: ${error.message}`);
    } finally {
      setLoading(false);
      setEditingDiscountId(null);
      // Limpiar los datos editados al finalizar la edición
      setEditedFields({});
      setIsEditing(false);
    }
  };

  const handleEditDiscount = (discountId, discount) => {
    setEditingDiscountId(discountId);
    // Establecer los campos editados para el descuento actual
    setEditedFields(discount);
    setIsEditing(true);
  };
  

  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiResponse = await helpHttp().get(process.env.REACT_APP_LEVELS_SERVICE + process.env.REACT_APP_LIST_LEVELS_SERVICE);
        if (!apiResponse.err) {
          setDiscounts(apiResponse);
          setApiData(apiResponse);
        }
      } catch (error) {
        console.error("Error en el efecto:", error);
      }
    };

    fetchData();
  }, [keyToUpdate]);

  const tableHeader = {
    backgroundColor: process.env.REACT_APP_APPBAR_COLOR,
    color: 'white',
    padding: '12px',
    textAlign: 'left',
  };

  const tableCell = {
    padding: '8px',
    textAlign: 'left',
  };

  const handleAcceptMessage = () => {
    setShowMessage(false);
    setOkForm(null);
    setKeyToUpdate(prevKey => prevKey + 1);
  };

  return (
    <Container>
      {loading && <Loader/>}
      {errorApi && <Message msg={errorApi} bgColor="#dc3545" onAccept={handleAcceptMessage}/>}
      {okForm && <Message msg={`${okForm.descripcion}`} bgColor="#008000" onAccept={handleAcceptMessage} />}
      {/* Resto del contenido del componente */}
      {!errorApi && !okForm && 
      <div style={{ display: 'flex' }}>
        <div style={{ flexGrow: 1, padding: '20px' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
            <Typography variant="h4">Panel de Administración de descuentos</Typography>
          </div>
          {apiData && apiData.length > 0 ? (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    {Object.keys(apiData[0]).map((columnName) => (
                      <TableCell key={columnName} style={tableHeader}>
                        {columnName}
                      </TableCell>
                    ))}
                    <TableCell style={tableHeader}>Acciones</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {discounts.map((discount) => (
                    <TableRow key={discount['Código']}>
                      {Object.keys(apiData[0]).map((columnName, index) => (
                        <TableCell key={`${discount['Código']}-${columnName}`} style={tableCell}>
                          {index < 2 ? (
                            discount[columnName]
                          ) : (
                            editingDiscountId === discount['Código'] ? (
                              <TextField
                                value={editedFields[columnName] || ''}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  setEditedFields((prevFields) => ({
                                    ...prevFields,
                                    [columnName]: value,
                                  }));
                                }}
                              />
                            ) : (
                              discount[columnName]
                            )
                          )}
                        </TableCell>
                      ))}
                      <TableCell style={tableCell}>
                        {editingDiscountId === discount['Código'] ? (
                          <>
                            <Button
                              variant="outlined"
                              color="primary"
                              onClick={() => handleSaveDiscount(discount['Código'], editedFields)}
                            >
                              Guardar
                            </Button>
                            <Button
                              variant="outlined"
                              color="error"
                              onClick={() => handleDrawerClose()}
                            >
                              Cancelar
                            </Button>
                          </>
                        ) : (
                          <Button
                            variant="outlined"
                            color="primary"
                            disabled={editingDiscountId !== null}
                            onClick={() => handleEditDiscount(discount['Código'], discount)}
                          >
                            Editar
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography variant="body1">Cargando...</Typography>
          )}
        </div>
        <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerClose}>
          <div style={{ width: '80%', maxWidth: 400, padding: 20, margin: 'auto' }}>
            {/* Aquí puedes renderizar el componente SignUp si es necesario */}
          </div>
        </Drawer>
      </div>
      }
    </Container>
  );
};

export default Discount;
